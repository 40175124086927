.MessageTalentModal {
  .p-dialog-content {
    max-width: 600px;
    min-width: 600px;
  }

  .MessageWrapper {
    label {
      display: inline-block;
      word-wrap: break-word;
    }
    .p-inputtextarea {
      width: 100%;
    }
  }
}
