@import "../../../mixins.scss";

.addAttendeesField {
  position: relative;
  border-radius: 5px;
  background: #f1f5f5;
  padding: 0px 13px;

  &.withClientAttendees {
    padding-bottom: 10px;

    .p-chips {
      border-bottom: 1px solid #cdcdcd;
    }
  }

  .p-chips {
    .p-chips-token {
      border-radius: 16px;
      font-size: 14px;
      line-height: 18px;
      min-height: 30px;
      margin: 3px;
    }

    .p-inputtext {
      padding-left: 0;
      padding-right: 0;
      font-size: 14px;
    }
  }

  .clientAttendees {
    margin-top: 7px;

    .p-chip {
      margin: 3px;
      cursor: pointer;
      background: transparent;
      border: 1px solid $brandColor;

      &.active {
        background: $brandColor;
        color: #fff;
      }

      &.disabled {
        pointer-events: none;
        background: #dcdcdc;
        border-color: #dcdcdc;
        color: #000;
        opacity: 0.8;
      }

      .pi {
        font-size: 0.8em;
      }
    }
  }
}
