@import "../../../mixins.scss";

.ApMultiCheckbox {
  .p-checkbox-box {
    &:hover {
      border-color: #bb1a1e !important;
    }
  }
  .p-field-checkbox {
    margin-top: 10px;
  }
  label {
    font-family: Metropolis, Helvetica Neue, sans-serif;
    font-weight: normal;
    &:first-child {
      font-weight: 700;
      font-size: 14px;
      .field-required &:after {
        color: $brandColor;
        content: "*";
        margin-left: 0.2em;
      }
    }
  }
  .p-checkbox-label {
    display: inline;
    cursor: pointer;
    width: auto;
  }
  .p-checkbox-label-description {
    display: inline-block;
    color: #999;
    font-size: 12px;
    margin-left: 28px;
  }
  &.error {
    > label,
    > sub {
      color: red;
    }
  }
}
