@import "../../mixins.scss";

.BookingAmendModal,
.BookingAmendConfirmModal {
  max-width: 740px;

  .p-dialog-footer {
    a {
      text-decoration: none;
    }
  }

  label {
    font-weight: 700;
  }
}
