@import "../../mixins.scss";

.BookingDatesModal {
  max-width: 740px;
  .field {
    margin: 1rem;
  }
  .Date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: $lightRedColor;
    color: #333333;
  }
}
