@import "../../mixins.scss";

.InterviewModal {
  width: 850px;
  max-width: 100%;
  .grid {
    padding-top: 10px;
    > .col {
      padding: 0 15px;
      @include for-size("tablet-portrait-up") {
        &:first-child {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 30px;
            bottom: 40px;
            right: 0;
            width: 1px;
            background: #dcdcdc;
          }
        }
      }
    }
  }
  .p-inputtextarea {
    resize: none;
  }
  .p-inputtext,
  .p-inputwrapper,
  .p-inputtextarea {
    width: 100%;
  }
  .p-multiselect {
    max-width: 100%;

    .p-multiselect-label {
      display: flex;
      flex-wrap: wrap;
    }

    .p-multiselect-token {
      margin: 5px;
    }
  }

  .p-datepicker {
    padding: 7px;
    width: 100%;
    .p-datepicker-header {
      padding: 5px;
      .p-datepicker-prev,
      .p-datepicker-next {
        width: 20px;
        height: 20px;
        .pi {
          font-size: 14px;
        }
      }
    }
    .p-datepicker-year,
    .p-datepicker-month {
      font-size: 14px;
    }
    table {
      font-size: 15px;
      margin: 5px;
      th,
      td {
        padding: 3px;
        > span {
          width: 35px;
          height: 35px;
        }
      }
    }
    .p-timepicker {
      padding: 3px 0 0;
      button {
        width: 16px;
        height: 20px;
        &:last-child {
          margin-top: 0;
        }
      }
      span {
        font-size: 16px;
      }
    }
  }

  .p-progressbar {
    margin-bottom: 5px;
  }

  footer > .p-button-secondary {
    border: 0;
    float: left;
  }
}
