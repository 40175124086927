@import "../../mixins.scss";

.p-autocomplete {
  .p-autocomplete-multiple-container {
    &:not(.p-disabled).p-focus {
      border-color: $brandColor;
    }

    .p-autocomplete-token {
      background: $brandColor;
    }
  }

  &.p-invalid {
    &.p-component {
      & > .p-inputtext {
        border-color: #a80000;
      }
    }
  }
}

.p-autocomplete-panel {
  .p-autocomplete-items {
    .p-autocomplete-item {
      &.p-highlight {
        background: $brandColor;
      }
    }
  }
}

.p-calendar {
  &.p-calendar-w-btn .p-datepicker-trigger {
    width: 3.157rem;
    border: none;
    background-color: #f1f5f5;
    color: #d4272b;

    &:not(a):not(.p-disabled):hover,
    &:not(a):not(.p-disabled):active {
      background-color: #f1f5f5;
      color: #d4272b;
    }
  }

  &.p-invalid {
    &.p-component {
      & > .p-inputtext {
        border-color: #a80000;
      }
    }
  }

  .pi {
    font-size: 1.2rem;
  }
}

.p-datepicker {
  .p-datepicker-header {
    .p-datepicker-prev {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }

      &:focus {
        box-shadow: 0 0 0 0.2em #ffe0e1;
      }
    }

    .p-datepicker-next {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }

      &:focus {
        box-shadow: 0 0 0 0.2em #ffe0e1;
      }
    }

    .p-datepicker-title {
      select {
        &:focus {
          border-color: $brandColor;
        }
      }
    }
  }

  table {
    td {
      & > span {
        &.p-highlight {
          background: $brandColor;
        }
      }

      &.p-datepicker-today {
        & > span {
          &.p-highlight {
            background: $brandColor;
          }
        }
      }
    }
  }

  .p-timepicker {
    button {
      color: #a6a6a6;

      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }
    }
  }

  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        background: $brandColor;
      }
    }
  }

  &:not(.p-disabled) {
    table {
      td {
        span {
          &:not(.p-highlight):not(.p-disabled):focus {
            box-shadow: 0 0 0 0.2rem #ffe0e1;
          }
        }
      }
    }
  }
}

.p-input-filled {
  .p-cascadeselect {
    background: #f4f4f4;

    &:not(.p-disabled):hover {
      background-color: #f4f4f4;
    }

    &:not(.p-disabled).p-focus {
      background-color: #f4f4f4;
    }
  }

  .p-checkbox {
    .p-checkbox-box {
      background-color: #f4f4f4;

      &.p-highlight {
        background: $brandColor;
      }
    }

    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .p-radiobutton {
    .p-radiobutton-box {
      &.p-highlight {
        background: $brandColor;

        &:not(.p-disabled):hover {
          background: $brandColor;
        }
      }
    }
  }
}

.p-checkbox {
  .p-checkbox-box {
    background: #f1f5f5;
    border-color: #f1f5f5;

    &.p-highlight {
      border-color: $brandColor;
      background: $brandColor;

      &:not(.p-disabled):hover {
        border-color: $brandColor;
        background: $brandColor;
      }
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        box-shadow: none;
        border-color: #f1f5f5;
      }

      &.p-highlight {
        &:hover {
          border-color: $brandColor;
          background: $brandColor;
        }
      }
    }
  }

  &.p-invalid {
    & > .p-checkbox-box {
      border-color: #a80000;
    }
  }
}

.p-dropdown {
  .p-dropdown-trigger {
    color: $brandColor;

    .pi {
      font-size: 0.8rem;
    }
  }

  &:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: $brandColor;
  }

  &.p-invalid {
    &.p-component {
      border-color: #a80000;
    }
  }
}

.p-dropdown-panel {
  border-radius: 5px;

  .p-dropdown-items {
    .p-dropdown-item,
    .p-dropdown-empty-message {
      padding: 1.029rem 0.857rem 1.029rem 1.229rem;
      text-transform: uppercase;
      background-color: #f1f5f5;
      color: #848484;

      &.p-highlight {
        background: $brandColor;
      }
    }
  }
}

.p-inputnumber {
  &.p-invalid {
    &.p-component {
      & > .p-inputtext {
        border-color: #a80000;
      }
    }
  }
}

.p-inputswitch {
  &.p-focus .p-inputswitch-slider {
    box-shadow: none;
  }

  .p-inputswitch-slider {
    background: $toggleBg !important;

    &:before {
      background: $disabledSwitchColor;
      box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
      content: "\e916";
      color: #fff;
      font-family: "icomoon" !important;
      speak: none;
      font-size: 0.5em;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-align: center;
      text-transform: none;
      line-height: 20px;
      vertical-align: text-top;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $toggleBg !important;

      &:before {
        background: $enabledSwitchColor;
        content: "\e915";
      }
    }
  }
}

.p-fluid {
  .p-inputtext {
    font-size: 15px;
  }
}

.p-inputtext {
  font-family: Metropolis, "Helvetica Neue", sans-serif;
  color: #000;
  background-color: #f1f5f5;
  border: none;
  border-radius: 5px;

  &:enabled {
    &:focus {
      border-color: $brandColor;
    }
  }

  &.p-invalid {
    &.p-component {
      border-color: #a80000;
    }
  }
}

.p-float-label {
  & > label {
    &.p-error {
      color: #a80000;
    }
  }
}

.p-listbox {
  border: none;
  border-radius: 5px;
  background-color: #f1f5f5;

  .p-listbox-list {
    .p-listbox-item {
      font-size: 15px;
      padding: 0.6rem 1rem;

      &.p-highlight {
        background: $brandColor;
      }
    }
  }

  &.p-invalid {
    border-color: #a80000;
  }
}

body .p-multiselect {
  background: #f1f5f5;
  border: none;
  border-radius: 5px;

  &:not(.p-disabled).p-focus {
    box-shadow: none;
  }

  .p-multiselect-label {
    padding: 15px 20px;
    font-size: 15px;
    line-height: 20px;
    color: #000;
  }

  &.p-inputwrapper-filled.p-multiselect-chip {
    .p-multiselect-label {
      padding: 0.7235rem 0.429rem;
    }
  }

  .p-multiselect-trigger-icon {
    color: $brandColor;
    font-size: 0.8rem;
  }

  &.p-multiselect-chip {
    .p-multiselect-token {
      background: #8e8e8e;
    }
  }

  &.p-invalid {
    &.p-component {
      border-color: #a80000;
    }
  }
}

.p-multiselect-panel {
  .p-multiselect-header {
    .p-multiselect-close {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .p-multiselect-items {
    .p-multiselect-item {
      &:focus {
        box-shadow: none;
      }

      &.p-highlight {
        background: $brandColor;
      }
    }
  }
}

.p-password {
  &.p-invalid {
    &.p-component {
      & > .p-inputtext {
        border-color: #a80000;
      }
    }
  }
}

.p-password-panel {
  .p-password-meter {
    .p-password-strength {
      &.weak {
        background: #e91224;
      }
    }
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    &:not(.p-disabled).p-focus {
      box-shadow: none;
      border-color: $brandColor;
    }

    border-color: transparent;
    background: #f1f5f5;

    &.p-highlight {
      border-color: $brandColor;
      background: #fff;
      border-width: 2px;

      &:not(.p-disabled):hover {
        border-color: $brandColor;
        background: #fff;
      }
    }

    .p-radiobutton-icon {
      background-color: #d4272b;
      width: 10px;
      height: 10px;
    }
  }

  &.p-invalid {
    & > .p-radiobutton-box {
      border-color: #a80000;
    }
  }
}

.p-selectbutton {
  .p-button {
    &.p-highlight {
      color: $brandColor;
      background: #fff;
      border: 1px solid $brandColor;
    }
  }

  &.p-invalid {
    & > .p-button {
      border-color: #a80000;
    }
  }
}

.p-slider {
  .p-slider-range {
    background: $brandColor;
  }

  .p-slider-handle {
    &:focus {
      box-shadow: none;
    }
  }

  &:not(.p-disabled) {
    .p-slider-handle {
      &:hover {
        background: 2px solid #666;
        border-color: $brandColor;
      }
    }
  }
}

.p-treeselect {
  &:not(.p-disabled).p-focus {
    border-color: $brandColor;
  }

  &.p-treeselect-chip {
    .p-treeselect-token {
      background: #8e8e8e;
      color: white;
    }
  }

  &.p-invalid {
    &.p-component {
      border-color: #a80000;
    }
  }
  .p-treeselect-trigger-icon {
    color: $brandColor;
    font-size: 0.8rem;
  }
}

.p-togglebutton {
  &.p-button {
    &.p-highlight {
      background: $brandColor;

      &:hover {
        background: $brandColor;
        border-color: $brandColor;
      }
    }

    &.p-invalid {
      & > .p-button {
        border-color: #a80000;
      }
    }
  }
}

.p-button {
  background: $brandColor;
  border: 1px solid $brandColor;
  border-radius: 5px;
  padding: 1.329em 2em;
  transition: background-color 0.1s ease-out;

  &:not(button):not(.p-disabled):hover {
    background: $brandColor;
    border-color: $brandColor;
  }

  &:not(button):not(.p-disabled):active {
    background: $brandColor;
    border-color: $brandColor;
  }

  &:not(a):not(.p-disabled):hover {
    background: $brandColor;
    border-color: $brandColor;
  }

  &:not(a):not(.p-disabled):active {
    background: $brandColor;
    border-color: $brandColor;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #ffe0e1;
  }

  &.p-button-outlined {
    color: $brandColor;

    &:not(button):not(.p-disabled):hover {
      color: $brandColor;
    }

    &:not(button):not(.p-disabled):active {
      color: $brandColor;
    }

    &:not(a):not(.p-disabled):hover {
      color: $brandColor;
    }

    &:not(a):not(.p-disabled):active {
      color: $brandColor;
    }
  }

  &.p-button-outlinedbutton {
    &:enabled {
      &:hover {
        color: $brandColor;
      }

      &:active {
        color: $brandColor;
      }
    }
  }

  &.p-button-outlineda {
    &:enabled {
      &:hover {
        color: $brandColor;
      }

      &:active {
        color: $brandColor;
      }
    }
  }

  &.p-button-text {
    color: $brandColor;

    &:not(button):not(.p-disabled):hover {
      color: $brandColor;
    }

    &:not(button):not(.p-disabled):active {
      color: $brandColor;
    }

    &:not(a):not(.p-disabled):hover {
      color: $brandColor;
    }

    &:not(a):not(.p-disabled):active {
      color: $brandColor;
    }
  }

  &.p-button-textbutton {
    &:enabled {
      &:hover {
        color: $brandColor;
      }

      &:active {
        color: $brandColor;
      }
    }
  }

  &.p-button-texta {
    &:enabled {
      &:hover {
        color: $brandColor;
      }

      &:active {
        color: $brandColor;
      }
    }
  }

  &.p-button-info {
    background: $brandColor;
    border: 1px solid $brandColor;

    &:not(a):not(.p-disabled):focus {
      box-shadow: 0 0 0 0.2rem #ffe0e1;
    }

    &.p-button-outlinedbutton {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }

    &.p-button-outlineda {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }

    &.p-button-text {
      background-color: transparent;
      color: $brandColor;

      &:not(button):not(.p-disabled):hover {
        color: $brandColor;
      }

      &:not(button):not(.p-disabled):active {
        color: $brandColor;
      }

      &:not(a):not(.p-disabled):hover {
        color: $brandColor;
      }

      &:not(a):not(.p-disabled):active {
        color: $brandColor;
      }
    }

    &.p-button-textbutton {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }

    &.p-button-texta {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }
  }

  &.p-button-danger {
    background: $brandColor;
    border-color: $brandColor;
  }
}

.p-fileupload-choose {
  &:not(button):not(a):not(.p-disabled):hover {
    background: #fff;
    border-color: $brandColor;
    color: $brandColor;
  }

  &.p-button-info {
    &.p-button-outlinedbutton {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }

    &.p-button-outlineda {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }

    &.p-button-text {
      background-color: transparent;
      color: $brandColor;

      &:not(button):not(.p-disabled):hover {
        color: $brandColor;
      }

      &:not(button):not(.p-disabled):active {
        color: $brandColor;
      }

      &:not(a):not(.p-disabled):hover {
        color: $brandColor;
      }

      &:not(a):not(.p-disabled):active {
        color: $brandColor;
      }
    }

    &.p-button-textbutton {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }

    &.p-button-texta {
      &:enabled {
        &:hover {
          color: $brandColor;
        }

        &:active {
          color: $brandColor;
        }
      }
    }
  }
}

.p-carousel {
  .p-carousel-content {
    .p-carousel-prev {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }
    }

    .p-carousel-next {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }
    }
  }
}

.p-paginator {
  margin-top: 20px;
  border: none;
  background: transparent;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    border-radius: 5px;
  }

  .p-paginator-pages {
    .p-paginator-page {
      border-radius: 5px;

      &.p-highlight {
        color: $brandColor;
        background: transparent;
        border: 1px solid $brandColor;
      }
    }
  }
  .p-dropdown-label.p-inputtext {
    padding-top: 9px;
  }
}

.AddMatchModal .p-paginator .p-paginator-pages {
  > button.p-paginator-page {
    width: auto;
    padding: 0;
  }
}

.p-picklist {
  .p-picklist-buttons {
    padding-left: 0;
    padding-right: 0;
  }

  .p-picklist-list {
    height: 80px;
    background: #f1f5f5;
    border: none;

    .p-picklist-item {
      font-size: 15px;
      color: #111;

      &.p-highlight {
        background: $brandColor;
      }

      &:focus {
        box-shadow: inset 0 0 0 0.2rem #e15c5f;
      }
    }
  }

  .p-picklist-header {
    font-size: 15px;
    color: #111;
    background: #dce7e7;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: none;
  }
}

.p-tree {
  .p-tree-container {
    .p-treenode {
      .p-treenode-content {
        .p-tree-toggler {
          &:enabled {
            &:hover {
              color: $brandColor;
            }
          }

          &:focus {
            box-shadow: 0 0 0 0.2rem #ffe0e1;
          }
        }

        .p-checkbox {
          margin-right: 0.5rem;

          .p-indeterminate {
            .p-checkbox-icon {
              color: #333;
            }
          }
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem #ffe0e1;
        }
      }
    }
  }
}

.p-treetable {
  .p-sortable-column {
    .p-sortable-column-badge {
      background: $brandColor;
    }

    &.p-highlight {
      background: $brandColor;
    }
  }

  .p-treetable-tbody {
    & > tr {
      & > td {
        .p-treetable-toggler {
          &:enabled {
            &:hover {
              color: $brandColor;
            }
          }
        }
      }
    }
  }

  .p-column-resizer-helper {
    background: $brandColor;
  }
}

.p-timeline {
  .p-timeline-event-marker {
    background-color: $brandColor;
  }
}

.p-accordion {
  .p-accordion-header {
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: $brandColor;
        border-color: $brandColor;
      }
    }
  }
}

.p-panel {
  .p-panel-header {
    .p-panel-header-icon {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }
    }
  }
}

.p-tabview {
  .p-tabview-nav {
    li {
      &.p-highlight {
        .p-tabview-nav-link {
          background: $brandColor;
          border-color: $brandColor;
        }
      }
    }
  }
}

.p-dialog {
  .p-dialog-header {
    .p-dialog-header-icon {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }

      &:focus {
        box-shadow: 0 0 0 0.2em #ffe0e1;
      }
    }
  }
}

.p-overlaypanel {
  .p-overlaypanel-close {
    background: $brandColor;
  }
}

.p-sidebar {
  .p-sidebar-header {
    .p-sidebar-close {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }
    }

    .p-sidebar-icon {
      &:enabled {
        &:hover {
          color: $brandColor;
        }
      }
    }
  }
}

.p-panelmenu {
  .p-panelmenu-header {
    &.p-highlight {
      & > a {
        background: $brandColor;
        border-color: $brandColor;
      }
    }
  }
}

.p-steps {
  .p-steps-item {
    .p-menuitem-link {
      background: transparent;
    }

    &.p-highlight {
      .p-steps-number {
        background: $brandColor;
      }
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    display: inline-flex;

    .p-tabmenuitem {
      &:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: #fff;
        color: #111;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
      }

      margin-right: 7px;

      .p-menuitem-link {
        border: none;
        font-size: 15px;
        color: #8e8e8e;
        font-weight: 400;
        border-radius: 5px;
        padding: 0.969rem 1rem;
        margin: 0;
      }

      &.p-highlight {
        .p-menuitem-link {
          background: #fff;
          color: #111;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.p-galleria {
  .p-galleria-indicators {
    .p-galleria-indicator {
      &.p-highlight {
        button {
          background: $brandColor;
        }
      }
    }
  }

  &.p-galleria-indicator-onitem {
    .p-galleria-indicators {
      .p-galleria-indicator {
        &.p-highlight {
          button {
            background: $brandColor;
          }
        }
      }
    }
  }
}

.p-tag {
  background: $brandColor;
  background: $brandColor;

  &.p-tag-info {
    background-color: $brandColor;
    background-color: $brandColor;
  }
}

.p-progressbar {
  height: 5px;

  .p-progressbar-value {
    background: $brandColor;
  }
}

.p-badge {
  background: $brandColor;

  &.p-badge-info {
    background-color: $brandColor;
  }
}

.p-datatable {
  .p-datatable-tbody {
    & > tr {
      &.p-highlight {
        color: initial;

        .pi {
          color: #333;

          &.p-checkbox-icon {
            color: #fff;
            margin-right: 0;
          }
        }
      }
    }
  }

  .p-datatable-loading-overlay {
    background: rgba(0, 0, 0, 0.05);
  }

  .p-sortable-column {
    &.p-highlight {
      .p-sortable-column-icon {
        color: #848484;
        background-size: contain;
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;

        &::before {
          display: none;
        }

        &.pi-sort-amount-up-alt {
          background-image: url("../../assets/sort-desc.svg");
        }

        &.pi-sort-amount-down {
          background-image: url("../../assets/sort-asc.svg");
        }
      }

      &:not(.p-sortable-disabled):hover {
        background-color: #e0e0e0;

        .p-sortable-column-icon {
          color: #848484;
        }
      }
    }
  }

  .p-datatable-footer {
    border-width: 2px 0 0 0;
    border-color: $lightGray;
    padding: 0.571rem 0.857rem;
  }
}

.p-link {
  font-family: Metropolis, "Helvetica Neue", sans-serif;
}

.p-link:focus,
.p-dialog .p-dialog-header .p-dialog-header-icon.p-dialog-header-close:focus,
.p-datepicker table td > span:focus,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus,
.p-menu .p-menuitem-link:focus,
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus,
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: $brandColor;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #dadada;
}

.p-datatable .p-datatable-tbody > tr {
  &:nth-child(even) {
    background-color: #f8fafa;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  outline-color: #ffe0e1;
}

body {
  .p-checkbox-label,
  .p-radiobutton-label {
    display: inline;
    margin: 0 0 0 0.5em;
    width: calc(100% - 30px);
    line-height: 20px;
    vertical-align: top;
  }
  .p-checkbox-label {
    display: inline-block;
  }

  .p-component {
    font-family: Metropolis, Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 18px;

    &.p-badge {
      line-height: 24px;
    }

    &.p-checkbox {
      display: inline-block;
      vertical-align: top;
    }

    &.p-radiobutton {
      display: inline-block;
      vertical-align: top;
    }

    &.p-dropdown,
    &.p-treeselect {
      background: #f1f5f5;
      border: none;
      border-radius: 5px;
    }

    &.p-inputtext {
      color: #111;
    }
  }

  .p-button {
    line-height: 14px;
  }

  .p-tabmenu {
    .p-tabmenu-nav {
      padding: 7px;
      background: #f1f5f5;
      border-radius: 5px;
      margin-bottom: 35px;
    }
  }
}

.p-button.p-button-rounded {
  border-radius: 5px;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
  background-color: #f4f4f4;
  color: #333;
  border: 1px solid #f4f4f4;

  &:hover,
  &:active {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
  }
}

.p-buttonset .p-button {
  &:not(:last-child) {
    border-right: 1px solid transparent;
  }

  &.p-highlight {
    border-right: 1px solid $brandColor;
  }
}

.p-button.p-button-secondary:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon.pi-star-fill,
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #000;
}

.p-picklist-buttons.p-picklist-target-controls {
  padding-right: 0;
}

.p-picklist-buttons {
  .p-button {
    background: none;
    color: #d4272b;
    border: none;
  }

  &.p-picklist-transfer-buttons {
    .pi-angle-right:before {
      content: "\e901";
    }

    .pi-angle-left:before {
      content: "\e900";
    }
  }

  &.p-picklist-target-controls {
    .pi-angle-up:before {
      content: "\e903";
    }

    .pi-angle-down:before {
      content: "\e902";
    }
  }
}

.icon-bookmark:before {
  content: "\e908";
  color: $brandColor;
}
.icon-paper-plane:before {
  content: "\e90a";
  color: $brandColor;
}
.icon-pencil:before {
  content: "\e904";
}
.icon-genie:before {
  content: "\e917";
  color: $brandColor;
}
.p-button.p-button-secondary:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):active {
  background-color: #c8c8c8;
}

.p-message .p-message-close:focus {
  box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.3);
}

.pi-info-circle:before {
  vertical-align: middle;
}

@include for-size(tablet-landscape-down) {
  .p-button {
    padding: 1em;
  }

  body {
    .p-component {
      font-size: 12px;
    }
  }
}

.field > label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.p-menu.p-menu-overlay {
  &:not(.action-buttons) {
    z-index: 1002 !important;
  }
}

.p-chips {
  .p-chips-multiple-container {
    &:not(:disabled).p-focus {
      box-shadow: none;
    }

    .p-chips-token {
      background: $brandColor;
    }
  }

  .p-inputtext {
    padding: 10px 15px;
  }

  .p-chips-input-token {
    width: 225px;
  }
}

.p-card {
  background: transparent;
}

.ap-inputtextarea-readonly {
  opacity: 0.5;
}

.filteringSelector {
  min-width: 203px;
  max-width: 520px;
  margin-right: 1rem !important;
  margin-bottom: 20px;
  line-height: 38px;
  padding-left: 13px;
  border-radius: 5px;
  color: #848484;
  outline: none;

  &.p-dropdown {
    line-height: 38px;
    .p-dropdown-label {
      text-transform: uppercase;
      color: $primaryColor;
    }
  }

  .p-treeselect-token {
    border-radius: 3px !important;
  }

  .p-inputtext,
  .p-treeselect-label,
  .p-dropdown-label {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
}

.ap-multiselect-switch {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  &-label {
    margin-left: 10px;
  }
}

.columnsSelect {
  margin: 0;
  .p-multiselect-label-container {
    display: none;
  }
  .p-multiselect-trigger {
    height: 30px;
  }
  .p-checkbox-box {
    &:hover {
      border-color: #bb1a1e !important;
    }
  }
  .p-field-checkbox {
    margin-top: 10px;
  }
}
