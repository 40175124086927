@import "../../mixins.scss";

.p-badge.badge-menu-invoices {
  margin-left: 5px;
}
.p-badge.badge-menu-avatar {
  margin-right: 5px;
}

.LayoutHeader {
  position: fixed;
  z-index: 1001;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625em 1.625em;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 16%);

  .logo-wrapper {
    display: flex;
    align-items: center;

    .logo-text {
      margin: 0;
      color: $brandColor;
    }
  }

  .right-block {
    display: flex;
    align-items: center;
  }

  .mobile-menu {
    display: none;
  }

  .admin {
    margin: 0 25px 0 15px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: #d4272b;
  }

  img {
    display: block;
    width: 40px;
  }

  nav > a {
    border-radius: 5px;
    display: inline-block;
    color: #8e8e8e;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    padding: 10px 1em;
    margin: 0 0.3125em;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #eaeaea;
      color: $primaryColor;
    }

    &.active {
      color: #111;
      font-weight: 700;
      h1 {
        font-weight: 700;
      }
    }
  }

  .user-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    user-select: none;
    font-size: 14px;
    font-weight: 700;
    padding-left: 18px;

    span {
      max-width: 225px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .default-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f1f5f5;
  }

  .user-avatar {
    border-radius: 50%;
    height: 40px;
    margin-left: 1em;
    object-fit: cover;
    width: 40px;
  }

  & + div {
    padding-top: 60px;

    > header {
      padding: 1.25em 2em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > h1 {
        margin: 0 !important;
      }

      > .steps {
        flex-grow: 1;
      }

      > div {
        display: flex;
        flex-direction: column;

        > div + h1 {
          margin-top: 0.5em !important;
        }

        > h1 {
          margin: 0 !important;
        }
      }

      .left-block,
      .right-block {
        flex-direction: row;
      }

      .left-block {
        display: flex;
        align-items: center;
        flex-grow: 0.2;
        min-height: 3.125em;
        > h1 {
          margin: 0 !important;
        }
      }

      .right-block a:last-child {
        margin-left: 15px;
      }

      a.header-back {
        border: 0;
        border-bottom: solid 1px transparent;
        font-size: 0.8em !important;
        font-weight: normal;
        display: inline;
        text-transform: uppercase;
        color: #d4272b;
        text-decoration: none;
        display: inline-block;
        border-radius: 5px;
        padding: 10px;
        font-size: 13px;
        font-weight: 700;

        &:hover {
          background: #eaeaea;
          color: #000;
        }

        > i.pi.pi-arrow-left {
          font-size: 0.8em;
          margin-right: 0.5em;
        }
      }

      input[type="search"] {
        height: 3.57em;
        border-radius: 5px;
        border-color: #eee;
        padding: 0.429em 1em;
        &:not(:only-child) {
          margin-right: 4em;
        }

        &:hover {
          border-color: #ddd;
        }
      }

      > a:not(:last-child) {
        margin-right: 1em;
      }

      .p-button {
        display: inline-block;
        line-height: 11px;
        transition: background-color 0.1s ease-out;

        .p-button-label {
          font-size: 14px;
          font-weight: 700;
          line-height: 10px;
          text-transform: uppercase;
          padding-top: 0.5em;
          padding-bottom: 0.5em;

          & + i {
            font-size: 1em;
            right: 1em;
          }
        }
      }
    }

    > div {
      margin: 0 2em 2em;
      justify-content: center;
    }
  }
}

#LayoutHeader__Logo {
  height: 40px;
  width: auto;
  margin-right: 8px;
}

@include for-size(desktop-down) {
  .LayoutHeader {
    nav > a {
      padding: 10px 0.5em;
    }
  }
}

@media (max-width: 1060px) {
  .LayoutHeader {
    .user-name {
      display: none;
    }
  }
}

@include for-size(tablet-landscape-down) {
  .LayoutHeader {
    padding: 5px 10px;

    .user-avatar {
      margin-left: 0.5em;
    }

    & + div > header .p-button .p-button-label {
      font-size: 12px;
    }

    & + div > div {
      margin: 0 1em 1em;
    }

    .user-button span {
      max-width: 100px;
      font-size: 14px;
    }

    .left-block > a {
      margin-right: 10px;
    }

    p.admin {
      display: none;
    }

    nav > a {
      font-size: 0.8em;
      margin: 0 0.2em;
      padding: 8px 0.2em;
    }
  }
}
@include for-size(phone-only) {
  .p-badge.badge-menu-avatar {
    margin-right: -17px;
    z-index: 101;
    margin-bottom: 15px;
  }

  .LayoutHeader {
    & + div > header {
      flex-direction: column;
      .right-block {
        justify-content: space-between;
        margin-top: 20px;
      }
    }

    .mobile-menu {
      display: block;
      background: #f1f5f5;
      border: 1px solid #f1f5f5;
    }
    .p-button-icon-only .p-button-label {
      display: none;
    }
    .pi-bars:before {
      color: black;
    }
    .desktop-menu {
      display: none;
    }
  }
}
