.BriefDatesDaysCount {
  &__permanent-brief-text {
    margin-bottom: 60px;
  }
  &__job-title {
    font-weight: bold;
  }
  &__stats {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2em 0;

    i:not(.pi-plus) {
      font-size: 2em;
    }

    div > span {
      display: block;
      margin-top: 0.5em;
    }

    .calendar-block {
      width: 140px;
    }
  }
}
