:root {
  --colorInactiveElements: rgba(17, 17, 17, 0.3);
}

.LoadingAnimation {
  z-index: 100;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: -80px;
  margin: auto;
  overflow: hidden;
  padding-top: 70px;

  .loading-status {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 15px 0 0;
  }

  .ellipse-check {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #c1d6d5;

    .pi {
      font-size: 1.4rem;
    }
  }
}

.carusel-loader {
  color: #e91224;
  display: none;
  font-size: 1.7em !important;
  background: #fff;
}

.outer-container {
  position: relative;
  max-width: 465px;
  margin: auto;
  display: flex;
  margin-top: 145px;
}

.outer-container:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 180%;
  top: -160px;
  left: 25px;
  background-color: rgba(220, 231, 231, 0.3);
}

.outer-container:after {
  position: absolute;
  left: -234px;
  top: -114px;
  content: "";
  width: 520px;
  height: 520px;
}

.outer-container.bg-1:after {
  background: transparent url("../../../assets/ellipse-1.png") no-repeat;
  background-position: 260px top;
}

.outer-container.bg-2:after {
  background: transparent url("../../../assets/ellipse-2.png") no-repeat;
  background-position: 260px top;
}

.outer-container.bg-3:after {
  background: transparent url("../../../assets/ellipse-3.png") no-repeat;
  background-position: 0 bottom;
}

.outer-container.bg-4:after {
  background: transparent url("../../../assets/ellipse-4.png") no-repeat;
  background-position: 0 bottom;
}

.outer-container > div {
  flex: 1;
}

.outer-container .carousel-wrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 24px;
  color: var(--colorInactiveElements);
  line-height: 1.3;
  transition: all 0.2s ease-in;
}

.outer-container .carousel-wrapper p.step {
  display: inline-block;
  margin: 0;
}

.outer-container .content {
  background: var(--colorInactiveElements);
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--colorInactiveElements);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  max-height: 450px;
  height: 450px;
}

.carousel-inner.no-blur:after {
  background: none;
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 112px;
  transition: transform 0.4s ease, opacity 0.4s ease;

  &.active p span.current {
    color: #d4272b;
    transition: all 0.2s ease-in;
  }

  .wrp-finish {
    display: flex;
  }
}

.futureStep .ellipse-check {
  display: none;
}

.carousel-item.active {
  p.step {
    transition: all 0.2s ease-in;
    color: #111;
  }

  .loading-status {
    .ellipse-check {
      display: none;
    }

    .carusel-loader {
      display: block;
    }
  }

  .wrp-finish .ellipse-check {
    display: flex;
  }
}

.carousel-item.visible {
  opacity: 1;
}

.carousel-wrapper .Finish {
  width: 312px;
  text-align: left;

  button {
    width: 100%;
  }

  .header-finish {
    font-size: 24px;
    margin-top: 10px;
  }

  .content-finish {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}
