.ApproveInvoiceModal {
  width: 500px;
  max-width: 100%;
  .p-dialog-content {
    display: flex;
  }
  .p-dialog-footer {
    .footer-btn {
      display: flex;
      justify-content: space-between;

      button {
        margin: 0;
        width: 138px;
      }
    }
  }
  .pi-spinner {
    margin: 0 auto;
  }
}
