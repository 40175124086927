.ApRichText {
  .ql-picker.ql-variables {
    width: 120px;
  }

  .ql-picker.ql-variables .ql-picker-item::before,
  .ql-picker.ql-variables .ql-picker-label::before {
    content: "Variables";
  }

  .ql-picker.ql-variables .ql-picker-item[data-label]:not([data-label=""])::before {
    content: attr(data-label);
  }

  .ql-tooltip {
    left: unset !important;
  }
}
