@import "../../mixins.scss";

.InterviewNotes {
  width: 500px;
  max-width: 100%;
  .p-inputtextarea {
    width: 100%;
  }

  footer > .p-button-secondary {
    border: 0;
    float: left;
  }
}
