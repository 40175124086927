@import "../../../mixins.scss";

body .p-checkbox .p-checkbox-box.p-highlight {
  border-color: $brandColor !important;
  background-color: $brandColor !important;
}

.checkbox-wrapper {
  + .checkbox-wrapper {
    margin-top: 10px;
  }
  .p-checkbox-label {
    cursor: pointer;
    width: auto;
  }
}
