@import '../../../mixins.scss';
.p-dropdown {
  .p-dropdown-filter-container {
    min-width: 100%;
  }
  .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
    color: $primaryColor;
  }
}

