.EditBookingRateModal {
  min-width: 280px;
  max-width: 320px;

  &.loading {
    .p-dialog-content {
      display: flex;
      justify-content: center;
    }
  }

  .p-radiobutton-label {
    cursor: pointer;
  }

  .p-radiobutton-label-description {
    font-size: 12px;
    color: #878787;
  }

  .p-dialog-footer .footer-btn {
    display: flex;
    justify-content: space-between;

    button {
      margin: 0;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .regenerate_sds {
    margin: 0;
    display: flex;
    align-items: center;

    label {
      margin: 0 0 0 5px;
      cursor: pointer;
    }
  }
}
