@import "../../../mixins.scss";

.ApFileUpload {
  font-family: $baseFont;
  a {
    color: $brandColor;
    text-decoration: none;
    font-weight: 600;
    padding: 11px 1rem 11px 0;
  }
  .preview-list {
    .preview {
      &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid #dcdcdc;
      }
    }
  }
  .preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: 0.9em;
    a {
      flex-grow: 10;
    }
    button[type="button"] {
      width: 40px;
      flex-grow: 1;
      padding: 10px;
    }
  }
  .description {
    color: #8e8e8e;
    font-size: 0.8em;
    margin-top: 7px;
  }
}
