.EditNotesModal {
  min-width: 700px;
  .p-dialog-content {
    p {
      margin: 1.5em 0 2.5em 0;
    }
    > input {
      margin-bottom: 10px;
      width: 100%;
    }
    .row-blocked {
      opacity: 0.5;
    }
  }
}
