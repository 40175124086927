@import "../../../mixins.scss";

.p-radiobutton-wrapper {
  .p-radiobutton .p-radiobutton-box {
    &.p-highlight {
      border-color: $brandColor !important;
      background-color: #fff !important;
    }
    &:not(.p-disabled).p-focus {
      box-shadow: none !important;
    }
  }
  .p-radiobutton-label {
    cursor: pointer;
  }
  .p-radiobutton-label-description {
    display: inline-block;
    color: #999;
    font-size: 0.9em;
    margin-left: 28px;
  }
}
