.selected-dates {
  display: flex;
  align-items: center;

  button.p-button {
    width: 30%;
  }

  .dates {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;

    > span {
      margin-bottom: 6px;
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  p.more {
    cursor: pointer;
    padding: 0 17px;
    color: #d4272b;
    border: 1px solid #d4272b;
    display: inline-block;
    margin: 0 10px;
    border-radius: 3px;
    transition: background-color 0.1s ease-out;

    &:hover {
      background-color: #be2327;
      color: #fff;
      border-color: transparent;
      transition: background-color 0.1s ease-out;
    }
  }
}

.selected-brief-dates > p {
  font-size: 16px;
  margin-top: 0;
  font-weight: bold;
  text-align: center;
}
