@import "../../mixins.scss";

.EditInvoiceModal {
  font-family: $baseFont;
  width: 1280px;
  max-width: 100%;
  &__form {
    &__lineItems {
      border-bottom: 1px solid #dce7e7;
      padding-bottom: 25px;
      margin-bottom: 25px;
      min-width: 800px;

      > button {
        margin-left: 70px;
      }

      .delete {
        flex: 25px 0 0;
        box-sizing: border-box;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: auto;
        }

        &:after {
          content: "\2715";
          display: inline-block;
          color: $brandColor;
          font-size: 20px;
          padding-top: 1px;
        }

        &.no-icon:after {
          content: "";
        }
      }

      .tb {
        font-weight: 700;
      }

      &__item {
        display: flex;
        padding: 8px 0;

        input.p-inputtext.p-component,
        .ApInputNumber input.p-inputtext.p-component,
        .ApCalendar .p-calendar > input,
        .p-inputtextarea.p-component,
        .ApCalendar .p-calendar,
        .p-dropdown > * {
          padding: 12px 10px;
          font-size: 13.5px !important;
          background-color: $lightGray;
          line-height: 1.5;
          &:disabled:not([name$=".total"]) {
            background-color: #f8fafa;
          }
        }

        .ApCalendar .p-calendar {
          background-color: transparent;
        }

        .p-dropdown,
        .ApCalendar .p-calendar {
          padding: 0;
        }

        textarea {
          min-height: 70px;
          padding: 6px 4px;
          resize: vertical;
        }

        > div {
          flex: 10;
          font-size: 14px !important;
          padding-right: 6px;
        }

        label {
          display: none;
        }

        .index {
          flex: 45px 0 0;
          text-align: center;
          font-weight: bold;
        }

        .description {
          flex: 390px;
          .p-inputtext.p-inputtextarea.p-component {
            width: 100%;
            min-height: 90px;
          }
        }

        .unit {
          flex: 180px 1 1;
          .p-component.p-dropdown {
            width: 100%;
          }
        }

        .amount {
          flex: 100px;
          input.p-inputtext.p-component {
            width: 100%;
            line-height: 1.2;
          }
        }

        .qty {
          flex: 50px;
          input.p-inputtext.p-component {
            width: 100%;
            line-height: 1.2;
          }
        }

        .dates {
          flex: 197px 0 1;
          .p-calendar.p-component.p-inputwrapper {
            width: 100%;
          }
        }

        .total {
          flex: 100px;
          input.p-inputtext.p-component {
            width: 100%;
          }
        }

        &:nth-child(n + 3) {
          .index {
            line-height: 1.9em;
          }
        }
      }
    }

    &__bottom {
      &.grid {
        margin: 0;
        font-size: 14px;

        .InputSwitchWrapper {
          display: flex;
          margin-bottom: 15px;
          align-items: center;

          .p-inputswitch {
            margin-right: 1rem;
            width: 48px;
          }

          label {
            max-width: 400px;
            flex: 1;
          }
        }

        .href {
          color: $brandColor;
          font-weight: 700;
          cursor: pointer;

          span {
            font-weight: inherit;
            color: #8e8e8e;
          }
        }

        table {
          tr {
            td {
              padding: 10px 20px 8px;
              font-size: 14px;
            }

            td:nth-child(1) {
              text-align: right;
              position: relative;
            }

            td:nth-child(2) {
              background: $lightGray;
              font-size: 15px;
              font-weight: 700;
              min-width: 120px;
            }
          }
        }
      }
    }
  }
}
