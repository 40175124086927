.RemoveInstanceModal {
  max-width: 550px;
  h3 {
    font-weight: 500;
    line-height: 24px;
    margin-top: 0;
  }
  .p-inputtextarea {
    width: 100%;
    resize: none;
  }
  .charCount {
    float: none;
  }
}
