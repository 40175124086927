@import "../../../mixins.scss";

.durationTypesPopup {
  text-align: left;
  h3 {
    margin-bottom: 0;
  }
  h4 {
    margin: 0;
    color: #666;
    font-weight: normal;
    font-size: 14px;
  }
  p {
    margin-top: 0;
    span {
      color: $brandColor;
      cursor: pointer;
    }
  }
  .video {
    position: relative;
    padding-bottom: 62.5%;
    height: 0;
    .iframe-loader {
      margin-left: 50%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
