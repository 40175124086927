@import "../../mixins.scss";

.Alerter {
  height: 0;
  left: 50%;
  position: fixed;
  right: 50%;
  top: 0;
  width: 0;
  z-index: 10001;
  > div {
    max-width: none;
    min-width: 100vw;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    @include for-size(tablet-landscape-up) {
      min-width: 450px;
    }

    &:not(:empty) {
      background: rgba(0, 0, 0, 0.5);
      padding: 60px;
      width: 100vw;
      height: 100vh;
    }

    > .p-message {
      background: #fff;
      border: 0;
      border-radius: 20px;
      color: #111;
      min-width: 100%;
      margin: 0 auto;

      > .p-message-wrapper {
        padding: 0;
      }

      footer {
        border-top: solid 1px #e5e5e5;
        display: flex;
        min-height: 60px;
        button {
          color: #8e8e8e;
          flex: 1;
          font: bold 14px/60px $baseFont;
          letter-spacing: 0.05em;
          line-height: 60px;
          margin: 0;
          position: relative;
          text-transform: uppercase;
          &:not(:last-child)::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            border-right: solid 1px #e5e5e5;
            right: 0;
          }
          &.p-button-success {
            color: $brandColor !important;
          }
          &.p-button-secondary:hover {
            color: #8e8e8e;
          }
          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .text-center {
        padding: 30px;
        @include for-size(tablet-landscape-up) {
          padding: 40px;
        }
      }

      @include for-size(tablet-landscape-up) {
        min-width: 450px;
        max-width: 50%;
      }
      .p-button {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        padding: 0;
        width: 50%;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
