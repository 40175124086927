.CreatePopupModal {
  width: 1000px;
  max-width: 100%;
  .p-inputtext,
  .p-dropdown,
  .p-inputnumber {
    width: 100%;
  }
  .field-availability {
    label {
      margin-bottom: 20px;
    }
  }
  .ApInputNumber > span > input.p-inputtext.p-component {
    padding: 14px 20px;
    border: 0;
  }

  [class*="col-"] {
    &.field {
      margin-bottom: 0;
    }
  }

  .p-dropdown-label {
    padding: 14px 20px;
  }
}
