.UserManagementForm {
  &_client {
    width: 600px;
    display: flex;
  }
  &__col {
    flex: 1 1 100%;
    padding: 0 10px;
    &:only-child {
      padding: 0;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .p-radiobutton-label-description,
  .p-checkbox-label-description {
    font-size: 12px;
    color: #878787;
  }
}
