@import "../../mixins.scss";

.Toaster {
  height: 0;
  left: 50%;
  position: fixed;
  right: 50%;
  top: 0;
  width: 0;
  z-index: 10000;

  > div {
    position: absolute;
    top: 0;
    max-width: 50%;
    min-width: 450px;
    transform: translate(-50%, 60px);
  }
}

.popup-item {
  cursor: pointer;
}

.popup-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  .container {
    position: absolute;
    background-color: #fff;
    z-index: 2001;
    width: 624px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 60px 0 60px;
    border-radius: 20px;
    .content {
      text-align: center;
      margin-bottom: 40px;
      font: 15px/20px $baseFont;
      color: #333;

      a {
        color: $brandColor;
        text-decoration: none;
        font-weight: 600;
      }
    }
    footer {
      min-height: 20px;
      margin: 0 -60px;
      &.isFetching {
        button {
          opacity: 0.2;
        }
      }
      button {
        background-color: transparent;
        border: 0;
        border-top: 1px solid #ececec;
        border-radius: 0;
        padding: 20px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        width: 100%;
        &.two {
          width: 50%;
        }
      }
      button:first-child,
      &.p-disabled {
        border-right: 1px solid #ececec;
        color: #8e8e8e;
        border-bottom-left-radius: 20px;
        cursor: pointer !important;
        pointer-events: all;
        opacity: 1;
      }
      button:first-child {
        &:not(.p-disabled).active {
          color: $brandColor;
        }
      }
      button:last-child {
        color: $brandColor;
        border-bottom-right-radius: 20px;
      }
    }

    @include for-size(phone-only) {
      width: 95%;
      padding: 30px 30px 0 30px;
    }
  }

  .popup-dashboard {
    li {
      margin: 10px 0;
      text-align: left;
      &::marker {
        color: $brandColor;
        font-size: 20px;
      }
    }
  }

  .iframe-loader {
    margin-top: 30%;
  }
}
