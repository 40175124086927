.GenerateMatchesModal {
  min-width: 280px;

  .body-matches-modal > p {
    margin-bottom: 24px;
  }

  &.p-dialog .p-dialog-content {
    padding-bottom: 36px;
  }

  .wrp-spinner {
    display: flex;
    justify-content: center;

    .pi-spinner {
      font-size: 2em !important;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
  }
}
