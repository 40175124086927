@import "../../../../mixins.scss";

.calendar-block {
  .p-fluid .p-inputnumber {
    width: 20%;
    margin: 5px 0 15px;

    input[type="text"] {
      display: none;
    }
  }
  .slider-block {
    display: flex;
  }
  .p-slider.p-slider-horizontal {
    width: 100%;
    margin: 1.15rem 1rem 1.5rem 0;
  }
}
@include for-size(phone-only) {
  .calendar-block .slider-block {
    display: block;
  }
}
