@import "../../mixins.scss";

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./../../assets/fonts/open-sans-v15-latin-300.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("./../../assets/fonts/open-sans-v15-latin-300.eot?#iefix") format("embedded-opentype"),
    url("./../../assets/fonts/open-sans-v15-latin-300.woff2") format("woff2"),
    url("./../../assets/fonts/open-sans-v15-latin-300.woff") format("woff"),
    url("./../../assets/fonts/open-sans-v15-latin-300.ttf") format("truetype"),
    url("./../../assets/fonts/open-sans-v15-latin-300.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./../../assets/fonts/open-sans-v15-latin-regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("./../../assets/fonts/open-sans-v15-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("./../../assets/fonts/open-sans-v15-latin-regular.woff2") format("woff2"),
    url("./../../assets/fonts/open-sans-v15-latin-regular.woff") format("woff"),
    url("./../../assets/fonts/open-sans-v15-latin-regular.ttf") format("truetype"),
    url("./../../assets/fonts/open-sans-v15-latin-regular.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./../../assets/fonts/open-sans-v15-latin-700.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("./../../assets/fonts/open-sans-v15-latin-700.eot?#iefix") format("embedded-opentype"),
    url("./../../assets/fonts/open-sans-v15-latin-700.woff2") format("woff2"),
    url("./../../assets/fonts/open-sans-v15-latin-700.woff") format("woff"),
    url("./../../assets/fonts/open-sans-v15-latin-700.ttf") format("truetype"),
    url("./../../assets/fonts/open-sans-v15-latin-700.svg#OpenSans") format("svg");
}

.form-error {
  color: $primaryColor;
  font-size: 0.8em;
}

.charCount {
  font-size: 0.8em;
  color: #888888;
  margin: 2px;
  float: right;
}

.slick-next:before,
.slick-prev:before {
  color: #000 !important;
}

#card-preview {
  margin: 0 auto;
  width: 50%;
}

html,
body {
  background-blend-mode: lighten;
  background-repeat: no-repeat;
  background-position-y: 60px;
  font-family: Metropolis, "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  position: relative;

  a {
    color: $primaryColor;
    text-decoration: underline;

    &:hover {
      color: darken($primaryColor, 5);
    }
  }

  .p-button,
  .p-selectbutton .p-button.p-highlight {
    &:enabled:hover,
    &:not(.p-disabled):hover {
      background-color: #fff;
      color: #be2327;
      border-color: #be2327;
    }

    &:enabled:active {
      background-color: #fff;
      color: darken($primaryColor, 5);
    }
  }

  .p-button,
  .p-selectbutton .p-button.p-highlight,
  .p-selectbutton .p-button {
    &:enabled:focus,
    &.p-focus {
      box-shadow: none;
    }
  }

  .p-slidemenu {
    min-width: 240px;
  }

  .p-steps-item {
    .p-menuitem-link:not(.p-disabled):focus {
      box-shadow: none !important;
    }

    &.p-steps-current .p-steps-number {
      background: $brandColor !important;
    }
  }

  .p-selectbutton {
    .p-button {
      line-height: 14px;
      border: 0;
      margin: 0 1px;

      .p-button-text {
        line-height: 28px;
      }
    }

    .p-button {
      border-radius: 5px !important;
      margin-right: 15px;
      color: #848484;
      background-color: #fff;
      border: 1px solid transparent;
    }

    .p-button:first-child {
      margin-left: 0;
    }

    .p-button:last-child {
      margin-right: 0;
    }
  }

  .p-inputtext {
    &:enabled:focus {
      box-shadow: none;
      border-color: darken($primaryColor, 8) !important;
    }
  }

  .p-datatable,
  .p-treetable {
    .p-datatable-thead > tr > th,
    .p-treetable-thead > tr > th {
      background: transparent;
      border: 0;
      border-bottom: solid 1px #f1f5f5;
      border-radius: 10px 10px 0 0;
      text-align: left;
    }

    .p-datatable-tbody > tr,
    .p-treetable-tbody > tr {
      > td {
        border: 0;
        border-bottom: solid 1px #f1f5f5;

        a {
          color: #000 !important;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: $primaryColor !important;
          }
        }
        .invalid-talent {
          color: $brandColor !important;
          &:hover {
            color: $brandColor !important;
          }
        }
      }
    }

    .p-datatable-thead th,
    .p-treetable-thead th {
      color: $primaryColor !important;
    }

    .p-sortable-column.p-highlight {
      background-color: transparent;
    }

    .p-sortable-column {
      position: relative;

      .p-sortable-column-icon {
        float: right;
        font-size: 1em !important;
        line-height: 19px;
        position: absolute;
        right: 0.857em;
      }
    }

    .p-sortable-column:not(.p-highlight) {
      &:hover {
        background: #f7f7f7;
      }

      .p-sortable-column-icon {
        visibility: hidden;
      }
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.published-status,
.matching-status,
.matching-dropdown > label {
  border-radius: 20px;
  color: #fff;
  padding: 0;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 100px;
  display: inline-block;

  &.published,
  &.requested {
    color: #ffba01;
    background-color: rgb(100%, 73%, 0%, 0.1);
  }

  &.pending {
    color: #8e8e8e;
    background-color: rgba(142, 142, 142, 0.1);
  }

  &.matchable,
  &.accepted {
    color: #4cbd30;
    background-color: rgba(76, 189, 48, 0.1);
  }

  &.draft,
  &.declined {
    color: #d32f2f;
    background-color: rgb(83%, 18%, 18%, 0.1);
  }
}
.published-status,
.matching-status {
  max-width: 207px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  text-align: center;
  padding: 3px 10px;
  border-radius: 4px;
}

.p-inputtext.p-inputtextarea.p-component {
  min-height: 200px;
}

.p-fluid > .p-inputtextarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.field.field-required > label::after {
  color: $brandColor;
  content: "*";
  margin-left: 0.2em;
}

.ap-error {
  font-size: 0.8em;
  color: red;
  line-height: 2em;
}

.published-ctl {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > label {
    margin-right: 1em;
  }
}

body .p-datatable .p-datatable-tbody > tr {
  height: 46.5px !important;
}

.p-fluid.field .p-picklist {
  display: flex;
  width: 100%;

  .p-picklist-listwrapper,
  .p-picklist-list {
    width: 100%;
  }
}

.badge {
  background: $brandColor;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 1.5em;
  margin-left: 1em;
  user-select: none;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"), url("../../assets/fonts/metropolis.woff2") format("woff2"),
    url("../../assets/fonts/metropolis.woff") format("woff"),
    url("../../assets/fonts/metropolis.ttf") format("truetype"), url("../../assets/fonts/metropolis.eot"),
    url("../../assets/fonts/metropolis.eot#MetropolisRegular") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

body .p-datatable.BriefView__matchings .p-datatable-tbody > tr > td {
  .p-row-toggler:focus {
    outline: none;
    box-shadow: none;
  }
}

@font-face {
  font-family: "icomoon";
  src: local("icomoon"), url("../../assets/fonts/icomoon.woff?prrcgn") format("woff"),
    url("../../assets/fonts/icomoon.ttf?prrcgn") format("truetype"),
    url("../../assets/fonts/icomoon.svg?prrcgn#icomoon") format("svg"), url("../../assets/fonts/icomoon.eot?prrcgn"),
    url("../../assets/fonts/icomoon.eot?prrcgn#iefix") format("embedded-opentype");
  font-weight: normal !important;
  font-style: normal !important;
  font-display: block !important;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: text-top;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error .path1:before {
  content: "\e90c";
  color: rgb(227, 34, 34);
}

.icon-error .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ThemedInput {
  > div {
    position: relative;
  }

  .ap-error {
    font: bold 15px/1.5 "Metropolis", Helvetica, Arial, sans-serif;
    transition: all 0.5s ease;
  }

  .p-inputtext.p-component {
    background: #f1f5f5;
    border: solid 2px transparent;
    border-radius: 5px;
    box-sizing: border-box;
    font: 15px/20px $baseFont;
    padding: 20px;
    width: 100%;
    outline: transparent;

    &::placeholder {
      color: #8e8e8e;
    }

    &:enabled {
      &:hover:not(.error) {
        border: solid 2px transparent;

        &.error {
          border-color: #e32222 !important;
        }
      }

      &:focus {
        box-shadow: none;

        &:not(.error) {
          border: solid 2px transparent !important;
        }
      }
    }

    &:-webkit-autofill {
      font-size: 15px !important;
    }

    &.error {
      border-color: #e32222 !important;
    }
  }

  .eye {
    bottom: 2px;
    height: 60px;
    position: absolute;
    right: 2px;
    width: 60px;
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    outline: none;

    .icon-eye {
      font-size: 60px;

      &:before {
        font-family: "icomoon";
        content: "\e905";
        color: #8e8e8e;
      }
    }
  }
}

.ThemedCheckbox {
  margin: 0 0 10px 0;
  display: inline-block;

  .ap-error {
    font: bold 15px/1.5 "Metropolis", Helvetica, Arial, sans-serif;
  }

  label {
    color: #989898;
    font-family: "Metropolis", Helvetica, Arial, sans-serif;
    line-height: 1.25em;

    a {
      color: #d4272b;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .p-checkbox-box {
    background: #f1f5f5 !important;
    box-shadow: none !important;
  }
}

body {
  .p-rating .p-rating-icon:focus {
    box-shadow: none;
  }
}

.p-dropdown,
.p-treeselect {
  .p-dropdown-label,
  .p-treeselect-label {
    font-size: 15px;
    line-height: 20px;
  }
}

.p-inputtext,
.p-treeselect .p-treeselect-label {
  padding: 15px 20px;
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.75rem 0.429rem;
}

.p-button-text {
  background-color: transparent !important;
  border: none !important;
}

.readonly-text {
  font-size: 1.2em;
  margin-bottom: 2rem;
}

.disclaimer {
  border: 1px solid #d4272b;
  border-radius: 5px;
  padding: 8px;
  font-weight: 600;
}

@include for-size(tablet-landscape-down) {
  body .p-selectbutton .p-button:first-child {
    padding-left: 1em;
  }

  body .p-selectbutton .p-button:last-child {
    padding-right: 1em;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.829rem 0.857rem 0.829rem 1.229rem;
  }

  .p-dropdown .p-dropdown-label {
    font-size: 12px;
    line-height: 23px;
  }
}

.UserPopup {
  max-width: 100%;
  width: 800px;
}

.noselect {
  user-select: none;
}

.red-bordered {
  display: inline-block;
  padding: 20px;
  border: 1px solid $brandColor;
  border-radius: 15px;

  h3 {
    margin-bottom: 10px;
    margin-top: 0;
    svg {
      vertical-align: text-bottom;
    }
  }

  p {
    margin-bottom: 0;
  }
  margin-bottom: 20px;
}

.ql-container {
  font-family: inherit;
}

@media screen and (min-width: 645px) {
  main {
    min-width: 645px;
  }
}

@media screen and (min-width: 1081px) {
  .desktop\: {
    &flex {
      display: flex;
    }

    &hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 1080px) {
  .phone\: {
    &hidden {
      display: none;
    }

    &flex {
      display: flex;
    }

    &block {
      display: block;
    }
  }
}
